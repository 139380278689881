.theme-one {
  /* client specification */
  --color-one: #ffa84b;
  --color-two: #ff8935;
  --color-three: #f18433;
  --color-four: #e16f44;
  --color-five: #cd4a1d;
  --color-six: #aa360f;

  --text-color: #a9a9a9; /* tbd */
  --text-color-headline: var(--text-color); /* tbd */

  --linear-gradient-color: linear-gradient(var(--color-one), var(--color-four));

  /* client specification */
  --hr-highlight-color: var(--color-two);

  /* color on highlight background */
  --on-highlight-color: #f4f4f4; /* tbd */

  & .subtheme-one {
    --gradient-start-color: #ffb776; /* not color-one (client specification) */
    --gradient-target-color: #db904c; /* not color-two (client specification) */
  }

  & .subtheme-two {
    --gradient-start-color: var(--color-three);
    --gradient-target-color: var(--color-four)
  }

  & .subtheme-three {
    --gradient-start-color: var(--color-five);
    --gradient-target-color: var(--color-six);
  }
}

.theme-two {
  /* client specification */
  --color-one: #8acb55;
  --color-two: #72cd53;
  --color-three: #63ba50;
  --color-four: #40a12c;
  --color-five: #2b8647;
  --color-six: #087742;

  --text-color: #a9a9a9; /* tbd */
  --text-color-headline: var(--text-color); /* tbd */

  --linear-gradient-color: linear-gradient(var(--color-one), var(--color-four));


  /* client specification */
  --hr-highlight-color: var(--color-three);

  /* color on highlight background */
  --on-highlight-color: #f4f4f4; /* tbd */

  & .subtheme-one {
    --gradient-start-color: var(--color-one);
    --gradient-target-color: var(--color-two);
  }

  & .subtheme-two {
    --gradient-start-color: var(--color-three);
    --gradient-target-color: var(--color-four)
  }

  & .subtheme-three {
    --gradient-start-color: var(--color-five);
    --gradient-target-color: var(--color-six);
  }
}

.theme-three {
  /* client specification */
  --color-one: #f4a0dc;
  --color-two: #f772d1;
  --color-three: #f272ed;
  --color-four: #c872f2;
  --color-five: #88c0f2;
  --color-six: #614cbe;

  --text-color: #a9a9a9; /* tbd */
  --text-color-headline: var(--text-color); /* tbd */

  --linear-gradient-color: linear-gradient(var(--color-one), var(--color-four));

  /* client specification */
  --hr-highlight-color: var(--color-two);

  /* color on highlight background */
  --on-highlight-color: #f4f4f4; /* tbd */

  & .subtheme-one {
    --gradient-start-color: var(--color-one);
    --gradient-target-color: var(--color-two);
  }

  & .subtheme-two {
    --gradient-start-color: var(--color-three);
    --gradient-target-color: var(--color-four)
  }

  & .subtheme-three {
    --gradient-start-color: var(--color-five);
    --gradient-target-color: var(--color-six);
  }
}

