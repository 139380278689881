.hr-alert {
  --backdrop-opacity: 0.7;

  .alert-wrapper {
    border-radius: 15px;
    border: 2px solid var(--hr-highlight-color);
  }

  .alert-button {
    background: var(--on-highlight-color);
    border-radius: 10px;
    color: var(--hr-highlight-color);
    border: 1px solid var(--hr-highlight-color);
  }

  .alert-button.alert-button-confirm {
    background-image: var(--linear-gradient-color);
    color: var(--on-highlight-color);
  }
}
