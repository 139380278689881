// Globale Styles for all pages or lib overwrites
//
div.bottom {
  > ion-img {
    padding-top: 30px;
    width: 450px;
    margin: auto;
  }
}

@import './app/pages/hr-cockpit/hr-cockpit.themes.css';
@import './app/services/hr-cockpit/alert.hr-alert.scss';
